<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <Spinner :spin="spin"/>
        <section class="header activity_questions">
            <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
            </a>
            <span class="fL nowrap">問答任務</span>
            <div class="icon iconButton"></div>
        </section>
        <!-- Main -->
        <section class="main activity_questions">
            <div v-if="campaigns.length > 0">
                <a
                    class="news flexH width margin between"
                    @click.prevent="toQA(campaign.campaignId)"
                    v-for="(campaign, index) in campaigns"
                    :key="'campaign'+index"
                >
                    <div class="info flexV padding margin height">
                        <span class="fL title">{{campaign.campaignName}}</span>
                        <span class="fS double"
                            >{{campaign.campaignDesc}}</span
                        >
                        <span class="fS gray">{{tsToDate(campaign.campaignStartTime)}} - {{tsToDate(campaign.campaignEndTime)}}</span>
                    </div>
                    <div class="reward flexV padding margin center middle">
                        <div class="img"><img src="@/assets/icon/point.svg"></div>
                        <!-- <span class="fM primary">100點</span> -->
                        <!-- 已完成 -->
                        <div class="done flexV center middle" v-if="checkCampaignCompleted(campaign)">
                            <div class="icon white"><font-awesome-icon icon="fa-solid fa-circle-check" size="2x"></font-awesome-icon></div>
                        </div>
                        <!-- 已領完 -->
                        <div class="over flexV center middle" v-else-if="checkRewardCompleted(campaign)">
                            <span class="fM white">已領完</span>
                        </div>
                    </div>
                </a>
            </div>
            <div v-else>
                <!-- 無內容 -->
                <div class="listEmpty flexV width center middle">
                    <div class="icon secondary"><font-awesome-icon icon="fa-solid fa-dice-d6" size="10x"></font-awesome-icon></div>
                    <!-- <span class="fXXL">本日沒有問答</span> -->
                    <span class="fL gray">目前沒有問答任務！</span>
                    <!-- <span class="fL gray">感謝參與回答，您已經回答過囉！</span> -->
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';

export default {
    components: {
        Spinner
    },
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaigns: [],
            spin: false
        };
    },
    mounted() {
        this.getCampaigns();
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        getDummyCampaigns() {
            let result = {
                uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
                memberId: 208,
                campaigns: [
                    {
                        campaignId: "M_RdROw7eY5B",
                        campaignName: "測試幸運輪盤任務",
                        campaignDesc: "輪盤抽獎。Yay!!",
                        campaignStatus: "approved",
                        campaignPrototype: "minigame",
                        campaignStartTime: 1652284800,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652322816,
                        missions: [
                            {
                                missionId: 44,
                                missionName: "幸運輪盤（小遊戲任務）",
                                missionDesc: "As title",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["38"]
                                        },
                                        status: "completed",
                                        completedAt: 1652341991
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_r5PDKD0m58",
                        campaignName: "PrePOB測試任務：抽獎小遊戲",
                        campaignDesc: "As title",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652112000,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652167000,
                        missions: [
                            {
                                missionId: 36,
                                missionName: "抽獎小遊戲",
                                missionDesc: "好禮大方送",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["31"]
                                        },
                                        status: "completed",
                                        completedAt: 1652170978
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_xd4wXAwvkj",
                        campaignName: "PrePOB測試任務：推薦",
                        campaignDesc:
                            "To recommend or not to recommend, that is the question.",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652025600,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652070487,
                        missions: [
                            {
                                missionId: 31,
                                missionName: "測試推薦任務 之 推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1652092171
                                    },
                                    {
                                        status: "completed",
                                        completedAt: 1652092477
                                    }
                                ]
                            },
                            {
                                missionId: 32,
                                missionName: "推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項"
                            },
                            {
                                missionId: 34,
                                missionName: "被推薦人關卡",
                                missionDesc:
                                    "接受他人推薦連結並成功註冊者可以領取獎項"
                            },
                            {
                                missionId: 35,
                                missionName: "註冊關卡",
                                missionDesc: "成功註冊者可以領取獎項"
                            }
                        ]
                    },
                    {
                        campaignId: "M_p5O76qR854",
                        campaignName: "註冊任務",
                        campaignDesc: "成功註冊桃園市民卡即可獲得100點",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1651132800,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1651129632,
                        missions: [
                            {
                                missionId: 22,
                                missionName: "會員註冊任務",
                                missionDesc:
                                    "只要成功註冊會員即可獲得，每人限領一次",
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652253394
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_qkwQ9ygK5L",
                        campaignName: "2022問答任務debug測試",
                        campaignDesc: "答對有獎，限量三名",
                        campaignStatus: "approved",
                        campaignPrototype: "test",
                        campaignStartTime: 1650816000,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1650862201,
                        missions: [
                            {
                                missionId: 16,
                                missionName: "問答任務 測試",
                                missionDesc: "有獎問答 測試",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1651387810
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: result});
                }, 1000);
            });
            return promise;
        },
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            this.spin = true;
            return (
                this.getCampaignAPI()
                    // this.getDummyCampaigns()
                    .then((res) => {
                        this.spin = false
                        this.campaigns = res.data.campaigns.filter(campaign => campaign.campaignPrototype == 'qna');
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
                    .catch(() => this.spin = false)
            );
        },
        toQA(campaignId) {
            this.$router.push({path: `/qa/${campaignId}`});
        },
        calCompletedMissions(campaign) {
            if (
                campaign &&
                campaign.missions &&
                campaign.missions.length > 0 &&
                campaign.missions.some((m) => m.participation)
            ) {
                return campaign.missions.filter((m) => {
                    if (
                        m.participation &&
                        m.participation.length > 0 &&
                        m.participation[0].status == "completed"
                    ) {
                        return true;
                    }
                    return false;
                }).length;
            }
            return 0;
        },
        calMissions(campaign) {
            if (
                campaign &&
                campaign.missions &&
                campaign.missions.length >= 0
            ) {
                return campaign.missions.length;
            }
            return 0;
        },
        checkCampaignCompleted(campaign) {
            return (
                this.calCompletedMissions(campaign) ==
                this.calMissions(campaign)
            );
        },
        checkRewardCompleted(campaign) {
            return (campaign.campaignBudget.availablePoints <= 0);
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        }
    }
};
</script>
